import { defineStore } from "pinia";
import { useSsrFetch } from "../composables/useFetch";
import type { ListingItem } from "../types/listing.type"
import type { Teammate, Banner, Categories, Projects } from "../types/home-components.type"

export const useHomeStore = defineStore({
	id: 'home',
	state: () => ({
		latestListings: [] as Array<ListingItem>,
		agentTeammate: [] as Array<Teammate>,
		showSplashScreen: 1,
		showDownloadApp: 1,
	}),
	getters: {
		latestListingsGetter: (state) => state.latestListings,
		agentTeammateGetter: (state) => state.agentTeammate,
	},
	actions: {
		async fetchLatestListings(): Promise<ListingItem[]> {
			return await useSsrFetch('pages/home/latest-listings') as ListingItem[] || [];
		},
		async fetchTopTenListings(): Promise<ListingItem[]> {
			return await useSsrFetch('pages/home/top-ten-listings') as ListingItem[] || [];
		},
		async fetchDiscountListings(): Promise<ListingItem[]> {
			return await useSsrFetch('pages/home/discount-listings') as ListingItem[] || [];
		},
		async fetchAgentTeammate(): Promise<Teammate[]> {
			const teammate = await useSsrFetch('pages/home/teammate') as Array<Teammate>;
			return teammate || [];
		},
		async fetchCountriesHasProperties(): Promise<Banner[]> {
			return await useSsrFetch('pages/home/banner') as Banner[] || [];
		},
		async fetchProjects(): Promise<Projects[]> {
			return await useSsrFetch('pages/home/projects') as Projects[] || [];
		},
		async fetchCategories(): Promise<Categories[]> {
			return await useSsrFetch('pages/home/categories') as Categories[] || [];
		}
	}
});
